import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './vocabulary.scss'
import AnimatedBg from '../../components/rwAnimatedBg/rwAnimatedBg'
import Menu from '../../components/menu/menu';
import OtherProjects from '../../components/otherProjects/otherProjects'

// img 
const logo = '/imgSvg/rw/logo.svg'
const pc = '/imgSvg/rw/pc.svg'
const phone = '/imgSvg/rw/phone.svg'
const translation = '/imgSvg/rw/translation.svg'

// icons
const icon1 = '/imgSvg/rw/ico/icon1.svg';
const icon2 = '/imgSvg/rw/ico/icon2.svg';
const icon3 = '/imgSvg/rw/ico/icon3.svg';
const icon4 = '/imgSvg/rw/ico/icon4.svg';
const icon5 = '/imgSvg/rw/ico/icon5.svg';
const icon6 = '/imgSvg/rw/ico/icon6.svg';
const icon7 = '/imgSvg/rw/ico/icon7.svg';
const icon8 = '/imgSvg/rw/ico/icon8.svg';
const icon9 = '/imgSvg/rw/ico/icon9.svg';
const icon10 = '/imgSvg/rw/ico/icon10.svg';
const icon11 = '/imgSvg/rw/ico/icon11.svg';
const icon12 = '/imgSvg/rw/ico/icon12.svg';
const icon13 = '/imgSvg/rw/ico/icon13.svg';
const icon14 = '/imgSvg/rw/ico/icon14.svg';
const icon15 = '/imgSvg/rw/ico/icon15.svg';
const icon16 = '/imgSvg/rw/ico/icon16.svg';
const bannerBg = '/imgSvg/rw/bannerBg.svg';
const bannerMobile = '/imgSvg/rw/bannerMobile.svg';
const bannerLaptop = '/imgSvg/rw/bannerLaptop.svg';
const extensionSectionBg = '/imgSvg/rw/extensionSectionBg.svg';
const extension1 = '/imgSvg/rw/extension1.svg';
const extension2 = '/imgSvg/rw/extension2.svg';
const blurredBg = '/imgSvg/rw/blurredBg.png';
const collection1 = '/imgSvg/rw/collection1.svg';
const collection2 = '/imgSvg/rw/collection2.svg';
const createCollectionPopup = '/imgSvg/rw/createCollectionPopup.svg';
const laptopAndMobile = '/imgSvg/rw/laptopAndMobile.svg';
const practice1 = '/imgSvg/rw/practice1.jpg';
const practice2 = '/imgSvg/rw/practice2.jpg';
const practice3 = '/imgSvg/rw/practice3.jpg';
const practice4 = '/imgSvg/rw/practice4.jpg';
const practice5 = '/imgSvg/rw/practice5.jpg';
const practice6 = '/imgSvg/rw/practice6.jpg';

const folders = '/imgSvg/rw/folders.svg'
const pcAndPhone = '/imgSvg/rw/pcAndPhone.svg'
const laptop2 = '/imgSvg/rw/pc2.svg'

const Vocabulary = (props) => {

   const location = useLocation();

   return (
      <div>
         <Menu />
         <div className='rw'>
            <div className='pt-20 sm:pt-28'>

               <div className="relative mx-auto overflow-hidden rw__container">
                  <div className="absolute top-0 left-0 w-full h-full z-0 rw__radius overflow-hidden">
                     <img className="h-full w-full object-cover" src={bannerBg} alt="blurredBg" />
                  </div>

                  <div className="rw__banner" >
                     <div className="mx-auto"><img className='w-16 sm:w-20' src={logo} data-aos="zoom-in-up" data-aos-delay="300" /></div>
                     <div className="text-dark text-xl text-center mt-8">
                        <p className='text-2xl sm:text-3xl lg:text-4xl xl:text-5xl' data-aos="zoom-in-up" data-aos-delay="300">Remember Words</p>
                        <p className='text-base sm:text-xl mt-1 opacity-70 font-light' data-aos="zoom-in-up" data-aos-delay="400">Take your language learning to the next level</p>
                     </div>

                     <div className='flex mt-14 w-fit justify-center'>
                        <div className="mt-16 -mr-20 sm:-mr-36 sm:-mr-32 z-10"><img src={bannerMobile} data-aos="fade-down-right" data-aos-delay="300" /></div>
                        <div className="block"><img src={bannerLaptop} data-aos="fade-up-left" data-aos-delay="300" /></div>
                     </div>
                  </div>

               </div>
            </div>

            <div className="rw__section py-14 justify-center md:py-20 lg:py-32 flex flex-col-reverse md:flex-row md:justify-between">
               <div className="flex-1 mt-10 mx-auto text-center w-full sm:w-3/5 md:w-auto md:mx-0 md:text-left text-rw-dark" >
                  <p className='text-xl md:text-2xl lg:text-3xl'>
                     Things what helps you to get better
                  </p>
                  <p className='font-light mt-4 text-sm sm:text-base'>RW helps you improve your vocabulary by seamlessly integrating into your daily routine. Available as a web app, mobile app, and browser extension, it allows you to save, organize, and practice words anytime, anywhere. </p>
               </div>
               <div className="rw__thingsSection ml-0 md:ml-10">
                  <div className="rw__thingsSection--pc">
                     <img src={pc} className="" />
                  </div>
                  <div className="rw__thingsSection--pc ml-5">
                     <img src={phone} className="" />
                  </div>
               </div>
            </div>

            <div className="rw__section items-center gap-10 py-14 justify-between md:py-10 flex flex-col md:flex-row md:justify-between">
               <div className="flex-2">
                  <img src={laptopAndMobile} className="w-full" />
               </div>
               <div className="flex-1 mt-0 sm:mt-10 mx-auto text-center w-full sm:w-3/5 md:w-auto md:mx-0 md:text-left text-rw-dark mb-5" >
                  <p className='text-xl md:text-2xl lg:text-3xl'>Learning service</p>
                  <p className='font-light mt-4 text-sm sm:text-base'>Track your progress and enhance your learning with RW. Monitor mastered words, review difficult ones, and practice with interactive exercises. Stay motivated with personalized insights and progress tracking across all your devices. </p>
               </div>
            </div>

            <div className='my-0 sm:my-20'>
               <div className="rw__container relative px-5 md:px-20 lg:px-24 py-16">
                  <div className="absolute top-0 left-0 w-full h-full rw__radius overflow-hidden z-0">
                     <img className="h-full w-full object-cover" src={extensionSectionBg} alt="extensionSectionBg" />
                  </div>

                  <div className="relative z-10">
                     <div className="text-center">
                        <p className='text-xl md:text-2xl lg:text-3xl'>Add new words while surfing the browser</p>
                        <p className='font-light mt-4 w-full sm:w-6/12 mx-auto text-sm sm:text-base'>With the extension, you can translate any words on any website.</p>
                     </div>
                     <div className="flex flex-col md:flex-row justify-center md:justify-between gap-10 mt-10">
                        <div className="flex-1"><img src={extension1} className="w-full" /></div>
                        <div className="flex-1"><img src={extension2} className="w-full" /></div>
                     </div>
                  </div>
               </div>
            </div>

            <div className='w-full relative my-20'>
               <div className="absolute top-0 left-0 w-full h-full z-0">
                  <img className="h-full w-full object-cover lg:object-fit" src={blurredBg} alt="blurredBg" />
               </div>
               <div className="relative z-10 rw__container px-10 md:px-20 lg:px-10 py-10 sm:py-20">
                  <div className="text-center">
                     <p className='text-xl md:text-2xl lg:text-3xl'>Organize</p>
                     <p className='font-light mt-4 w-full sm:w-6/12 mx-auto text-sm sm:text-base'>Organize your words into collections for better learning. Create, manage, and practice words in structured sets.</p>
                  </div>
                  <div className="flex flex-col md:flex-row justify-center md:justify-between gap-10 mt-10">
                     <div className="flex-1"><img src={collection1} className="w-full" /></div>
                     <div className="flex-1"><img src={collection2} className="w-full" /></div>
                  </div>
               </div>
            </div>

            <div className="rw__container relative px-10 md:px-20 lg:px-24 py-16">
               <div className="absolute top-0 left-0 w-full h-full rw__radius overflow-hidden z-0">
                  <img className="h-full w-full object-cover" src={extensionSectionBg} alt="extensionSectionBg" />
               </div>

               <div className="relative z-10">
                  <div className="flex flex flex-col md:flex-row justify-center md:justify-between gap-5 sm:gap-20 w-full sm:w-10/12 mx-auto items-center">
                     <div className="flex-1"><img src={createCollectionPopup} alt="create collection popup image" className="w-full" /></div>
                     <div className='flex-1'>
                        <div className="text-xl md:text-2xl lg:text-3xl">Tags</div>
                        <p className='font-light mt-4 text-sm sm:text-base'>Easily organize words within your collection using tags and categories for better learning and quick access.</p>
                     </div>
                  </div>
               </div>
            </div>

            <div className='rw__container py-10 md:py-32'>
               <div className='text-center'>
                  <div className="text-xl md:text-2xl lg:text-3xl">Practice your words anytime, wherever you want.</div>
                  <p className='font-light mt-4 text-sm sm:text-base'>Just choose the collections and type of practice or all types at once and go!</p>
               </div>

               <div className='columns-2 sm:columns-3 gap-8 mt-10'>
                  <img className='w-full' src={practice1} alt="practice step 1 example image" />
                  <img className='w-full' src={practice2} alt="practice step 2 example image" />
                  <img className='w-full' src={practice3} alt="practice step 2 example image" />
                  <img className='w-full' src={practice4} alt="practice step 2 example image" />
                  <img className='w-full' src={practice5} alt="practice step 2 example image" />
                  <img className='w-full' src={practice6} alt="practice step 2 example image" />
               </div>
            </div>

            <div className="rw__section flex py-12 md:py-20 flex-wrap flex-col md:flex-row">
               <div className="flex flex-col mt-10 mr-10">
                  <div>
                     <div className="text-gray uppercase text-base font-medium">colors</div>
                     <div className="flex mt-3 flex-wrap" data-aos="zoom-in" data-aos-delay="200">
                        <div className="bg-rw-gray w-12 h-12 rounded-full mr-3"></div>
                        <div className="bg-rw-yellow w-12 h-12 rounded-full mr-3"></div>
                        <div className="bg-rw-purple w-12 h-12 rounded-full mr-3"></div>
                        <div className="bg-rw-black w-12 h-12 rounded-full mr-3"></div>
                     </div>
                  </div>

                  <div className="mt-10 lg:mt-20">
                     <div className="text-gray uppercase text-base font-medium">typography</div>
                     <div className="mt-3 text-4xl sm:text-6xl text-rw-purple font-light" data-aos="zoom-in" data-aos-delay="300">Poppins</div>
                     <div className="flex mt-3 items-center mt-10" data-aos="zoom-in" data-aos-delay="300">
                        <div className="flex flex-col">
                           <div className="">Regular</div>
                           <div className="text-5xl">Aa</div>
                        </div>
                        <div className="flex flex-col ml-16 mt-1">
                           <div className="font-medium">Medium</div>
                           <div className="text-5xl font-medium">Aa</div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="flex flex-col flex-wrap mt-10 w-full lg:w-auto flex-1 items-start lg:items-center">
                  <div>
                     <div className="text-gray uppercase text-base font-medium">icons</div>
                     <div className="mt-3 rw__colorsSectionIcons">
                        <img src={icon1} />
                        <img src={icon2} />
                        <img src={icon3} />
                        <img src={icon4} />
                        <img src={icon5} />
                        <img src={icon6} />
                        <img src={icon7} />
                        <img src={icon8} />
                        <img src={icon9} />
                        <img src={icon10} />
                        <img src={icon11} />
                        <img src={icon12} />
                        <img src={icon13} />
                        <img src={icon14} />
                        <img src={icon15} />
                        <img src={icon16} />
                     </div>
                  </div>
                  <div className="mt-10 lg:mt-20">
                     <div className="text-gray uppercase text-base font-medium">card ui</div>
                     <div className="mt-3">
                        <div><img src={folders} /></div>
                     </div>
                  </div>
               </div>
            </div>

            <OtherProjects location={location.pathname} />

         </div>
      </div>
   )
}

export default Vocabulary
