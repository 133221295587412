import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './dogWalking.scss'
import Menu from '../../components/menu/menu'
import DogAnimatedEyes from '../../components/dogAnimatedEyes/dogAnimatedEyes'
import OtherProjects from '../../components/otherProjects/otherProjects'

// images
const logo = '/imgSvg/dogWalking/logo.svg'
const phones = '/imgSvg/dogWalking/phones.svg'
const phones2 = '/imgSvg/dogWalking/phones2.svg'
const laptop = '/imgSvg/dogWalking/laptop.svg'
const cards = '/imgSvg/dogWalking/cards.svg'
const pcAndPhone = '/imgSvg/dogWalking/pcAndPhone.svg'


// icons 
const icon1 = '/imgSvg/dogWalking/ico/icon1.svg'
const icon2 = '/imgSvg/dogWalking/ico/icon2.svg'
const icon3 = '/imgSvg/dogWalking/ico/icon3.svg'
const icon4 = '/imgSvg/dogWalking/ico/icon4.svg'
const icon5 = '/imgSvg/dogWalking/ico/icon5.svg'
const icon6 = '/imgSvg/dogWalking/ico/icon6.svg'
const icon7 = '/imgSvg/dogWalking/ico/icon7.svg'
const icon8 = '/imgSvg/dogWalking/ico/icon8.svg'
const icon9 = '/imgSvg/dogWalking/ico/icon9.svg'
const icon10 = '/imgSvg/dogWalking/ico/icon10.svg'
const icon11 = '/imgSvg/dogWalking/ico/icon11.svg'
const icon12 = '/imgSvg/dogWalking/ico/icon12.svg'


const DogWalking = () => {

   const location = useLocation();

   return (
      <div>
         <Menu/>
         <div className="relative">
            <div className=""><img className="w-full hidden sm:block" src='./img/dogWalking/banner.png' /></div>
            <div className=""><img className="w-full block sm:hidden" src='./img/dogWalking/bannerPhone.png' /></div>
            <div className="dog__bannerSection">
              <DogAnimatedEyes />
               <div className="text-center sm:text-left text-lg lg:text-2xl mt-4 sm:mt-2 lg:mt-6" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="300">Mobile App for searching dog walker</div>
            </div>
         </div>

         <div className="work__section px-10">
            <img className="mx-auto mt-10 py-5 md:py-20" src={phones} />
         </div>

         <div className="work__section flex py-10 justify-center md:py-20 lg:py-24 flex flex-col md:flex-row md:justify-between items-center">
            <div className="flex flex-col">
               <div className="text-2xl md:text-3xl lg:text-4xl mx-auto text-center md:w-4/5 md:mx-0 md:text-left" data-aos="fade-left" data-aos-duration="1000">Find the dog walker next to you</div>
               <div className="text-base md:text-lg md:w-4/5 my-5 text-center sm:text-left text-dog-gray" data-aos="fade-left" data-aos-duration="1200">In the search there is a filter that helps to select the dogwalker</div>
            </div>
            <div className="mt-10 md:mt-0 w-10/12"><img src={laptop} /></div>
         </div>

         <div className="work__section flex py-5 md:py-20 flex-wrap flex-col md:flex-row justify-between">

            <div className="flex flex-col mt-10 mr-10">
               <div>
                  <div className="text-gray uppercase text-base font-medium">colors</div>
                  <div className="flex mt-3 flex-wrap" data-aos="zoom-in" data-aos-delay="200">
                     <div className="bg-dog-pink w-12 h-12 rounded-full mr-3"></div>
                     <div className="bg-dog-lightBlue w-12 h-12 rounded-full mr-3"></div>
                     <div className="bg-dog-gray w-12 h-12 rounded-full mr-3"></div>
                     <div className="bg-dog-lightGray w-12 h-12 rounded-full mr-3"></div>
                  </div>
               </div>

               <div className="mt-10 lg:mt-20">
                  <div className="text-gray uppercase text-base font-medium">typography</div>
                  <div className="mt-3 text-4xl sm:text-6xl text-dog-pink font-light" data-aos="zoom-in" data-aos-delay="300">Poppins</div>
                  <div className="flex mt-3 items-center mt-10" data-aos="zoom-in" data-aos-delay="300">
                     <div className="flex flex-col">
                        <div className="">Regular</div>
                        <div className="text-5xl">Aa</div>
                     </div>
                     <div className="flex flex-col ml-16 mt-1">
                        <div className="font-medium">Medium</div>
                        <div className="text-5xl font-medium">Aa</div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="flex flex-col flex-wrap mt-10 w-full lg:w-auto">
               <div>
                  <div className="text-gray uppercase text-base font-medium">icons</div>
                  <div className="mt-3 rw__colorsSectionIcons">
                     <img src={icon1} />
                     <img src={icon2} />
                     <img src={icon3} />
                     <img src={icon4} />
                     <img src={icon5} />
                     <img src={icon6} />
                     <img src={icon7} />
                     <img src={icon8} />
                     <img src={icon9} />
                     <img src={icon10} />
                     <img src={icon11} />
                     <img src={icon12} />
                  </div>
               </div>
               <div className="mt-10 lg:mt-20">
                  <div className="text-gray uppercase text-base font-medium">card ui</div>
                  <div className="mt-3">
                     <div><img src={cards} /></div>
                  </div>
               </div>
            </div>
         </div>

         <div className="work__section px-5">
            <img className="mx-auto mt-10 py-5 md:py-12" src={phones2} />
         </div>

         <div className="work__section flex flex-col-reverse md:flex-row justify-center md:justify-between py-12 md:py-24">
            <div className="flex-2 px-10 md:px-0 mt-10 md:mt-0"><img src={pcAndPhone} /></div>
            <div className="flex flex-col pl-0 md:pl-10">
               <div className="text-2xl md:text-3xl lg:text-4xl mx-auto text-center md:w-4/5 md:mx-0 md:text-left" data-aos="fade-left"data-aos-duration="1000">Leave a review about the walk</div>
               <div className="text-base md:text-lg md:w-4/5 my-5 text-center md:text-left text-dog-gray" data-aos="fade-left" data-aos-duration="1200">Leave your feedback and help other users choose the dogwalker</div>
            </div>
         </div>

         <OtherProjects location={location.pathname} />

      </div>
   )
}

export default DogWalking
