import React from 'react'
import { useLocation } from 'react-router-dom'
import Menu from '../../components/menu/menu';
import OtherProjects from '../../components/otherProjects/otherProjects'
import './gasTrainer.scss';

// img 
const desktop = '/imgSvg/gasTrainer/desktop.svg';
const laptop1 = '/imgSvg/gasTrainer/laptop1.svg';
const laptop2 = '/imgSvg/gasTrainer/laptop2.svg';
const screenshots = '/imgSvg/gasTrainer/screenshots.svg';
const screenshot1Mobile = '/imgSvg/gasTrainer/screenshot1-mobile.svg';
const screenshot2Mobile = '/imgSvg/gasTrainer/screenshot2-mobile.svg';
const screenshot3Mobile = '/imgSvg/gasTrainer/screenshot3-mobile.svg';
const selectraLogo = '/imgSvg/selectra/selectraLogo.svg';
const selectraLogoLight = '/imgSvg/selectra/selectraLogoLight.svg';


const GasTrainer = (props) => {

    const location = useLocation();

    return (
        <div>
            <Menu />
            <div className="relative gasTrainer__section">

                <div className="flex flex-col md:flex-row justify-center md:justify-between py-12 md:pt-44 md:pb-10 gap-10">
                    <div className='flex-col flex-1 mt-10 lg:mt-28'>
                        <div className="text-3xl md:text-5xl lg:text-7xl font-medium mx-auto w-max md:mx-0">Gas Trainer</div>
                        <div className='flex gap-3 mt-1 w-max mx-auto md:mx-0'>
                            <span className='text-lg md:text-2xl'>by</span>
                            <span><img src={selectraLogo} className="w-32" /></span>
                        </div>

                        <div className='mt-8 gasTrainer__bannerText'>
                        The app that simplifies tracking natural gas usage and expenses. Gas Trainer empowers users to easily input their meter readings and receive instant insights into their consumption patterns and costs through visually appealing graphs
                        </div>
                    </div>
                    <div className="flex-1"><img src={desktop} className="" /></div>
                </div>

                <div className="flex flex-col-reverse md:flex-row justify-center md:justify-between py-12 xl:py-16 gap-10 items-center">
                    <div className="flex-1 xl:flex-2"><img src={laptop1} className="" /></div>
                    <div className='flex-col flex-1'>
                        <span className="text-2xl md:text-3xl lg:text-5xl">Registration page</span>
                        <div className='mt-4 sm:mt-8 gasTrainer__bannerText'>
                            The registration page is the web page where the user can create his account on the site. This page usually contains a form that consists of various fields that the user must fill out.
                        </div>
                        <div className='mt-8 flex gap-5 items-center'>
                            <div className='py-2 px-6 text-selectra-blue text-sm font-medium  bg-selectra-lightBlue rounded-full'>Sign Up</div>
                            <div className='py-2 px-6 text-selectra-lightGray text-sm font-medium border border-selectra-lightGray rounded-full'>Log In</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row justify-center md:justify-between py-12 xl:py-16 gap-10 items-center">
                    <div className='flex-col flex-1 mt-0 md:mt-20'>
                        <span className="text-2xl md:text-3xl lg:text-5xl">Profile page</span>
                        <div className='mt-4 sm:mt-8 gasTrainer__bannerText'>
                            A profile page is a webpage or online space dedicated to presenting an individual or organization's information. It serves as an online representation of a person or business and can provide a snapshot of their identity, interests, achievements, and goals.
                        </div>
                    </div>
                    <div className="flex-1 xl:flex-2"><img src={laptop2} className="ml-auto" /></div>
                </div>

                <div className='gasTrainer__gradientSection pt-10 pb-10 lg:pb-0 lg:pt-20 px-5 sm:px-10 lg:px-20  my-10 md:my-24'>
                    <div className="text-2xl md:text-3xl lg:text-5xl text-white -mb-0 xl:-mb-20">Mobile adaptive</div>
                    <div className="hidden md:block flex-2"><img src={screenshots} className="" /></div>
                    <div className='block md:hidden mt-3'>
                        <div className=""><img src={screenshot1Mobile} className="" /></div>
                        <div className="-mt-10 sm:-mt-20"><img src={screenshot3Mobile} className="" /></div>
                        <div className=""><img src={screenshot2Mobile} className="ml-auto" /></div>
                    </div>
                </div>

                <div className='py-16 lg:py-20'>
                    <div className='w-min mx-auto flex flex-col md:flex-row'>
                        <div className='gasTrainer__circleText'>Сalculates gas costs</div>
                        <div className='gasTrainer__circleText'>Shows the forecast until the end of the month</div>
                        <div className='gasTrainer__circleText'>There is a profile where your information is saved</div>
                        <div className='gasTrainer__circleText'>There is a web version and an adaptive mobile version</div>
                    </div>
                    <div className='gasTrainer__line'></div>
                    <div className="mx-auto w-max mt-8"><img src={selectraLogoLight} className="w-40 sm:w-auto" /></div>
                </div>
            </div>

            <OtherProjects location={location.pathname} />

        </div>
    )
}

export default GasTrainer
