import React, { useState, useEffect } from 'react'
import './menu.scss'
import { useLocation } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';
import { NavLink } from "react-router-dom";
import { Spiral as Hamburger } from 'hamburger-react'
import { ContentData } from '../../contentData'

const logo = '../../imgSvg/logo.svg';
const logoLight = '../../imgSvg/logoLight.svg';
const callBlue = '../../imgSvg/callBlue.svg';
const callWhite = '../../imgSvg/callWhite.svg';
const callDark = '../../imgSvg/callDark.svg';



const Menu = (props) => {
   const location = useLocation();
   const checkLocation = () => ContentData.Projects.some(x => x.link === location.pathname)

   const [isOpen, setOpen] = useState(false)

   const closePhoneMenu = (e) => {
      e.stopPropagation();
      setOpen(false)
   }


   const WhiteMenu = () => {
      return (
         <>
            {/* <div className="menuBottomShadow"> */}
            <div className={location.pathname === '/siteForPrint' || location.pathname === '/parking' ? 'section-menu-wrapper-shadow' : 'section-menu-wrapper'}>
               <div className="section-menu flex flex-row md:flex-row justify-between mx-auto items-center whitespace-nowrap">
                  <NavLink to="/" className="py-5 mr-5 md:py-0"><img src={logoLight} className="cursor-pointer w-28 md:w-32 lg:w-full" /></NavLink>
                  <div className="md:flex hidden items-center">
                     <Link exact to="/#home" className="menuItemLight">Home</Link>
                     <Link exact to="/#services" className="menuItemLight">Services</Link>
                     <Link to="/#reviews" className="menuItemLight">Reviews</Link>
                     <Link exact to="/#portfolio" className="menuItem--activeLight">Portfolio</Link>
                     <Link exact to="/#technologies" className="menuItemLight">Technologies</Link>
                     <Link exact to="/#contact" className="menuItemLight">Contact Us</Link>
                     <a href='https://calendly.com/andreytyndyk/30min' target='call'>
                        <div className="border-2 border-white py-1 px-1 lg:px-3 flex w-28 lg:w-auto rounded-md text-white bg-transparentWhite hover:bg-dog-lightGray transition cursor-pointers">
                           <img src={callWhite} className="w-4 lg:w-auto"/>
                           <span className="uppercase ml-2 lg:ml-3 font-medium pt-1 text-xs xl:text-base">book a call</span>
                        </div>
                     </a>
                  </div>
               </div>
               </div>
            {/* </div> */}

            <div className={isOpen ? "burger--activeLight md:hidden block z-40" : "md:hidden block z-40 burgerLight"}>
               <div className="burgerIcon">
                  <Hamburger toggled={isOpen} toggle={setOpen} size={25} />
               </div>
            </div>
         </>
      )
   }

   const DefaultMenu = () => {
      return (
         <>
         {/* <div className="menuBottomShadow"> */}
         <div className={location.pathname === '/siteForPrint' || location.pathname === '/parking' ? 'section-menu-wrapper-shadow' : 'section-menu-wrapper'}>
            <div className="section-menu flex flex-row md:flex-row justify-between mx-auto items-center whitespace-nowrap">
               <NavLink to="/" className="py-5 mr-5 md:py-0"><img src={logo} className="cursor-pointer w-28 md:w-32 lg:w-full" /></NavLink>
               <div className="md:flex hidden items-center">
                  <Link to="/" className={location.pathname === "/" ?  "menuItem--active" :"menuItem"}>Home</Link>
                  <Link to="/#services" className="menuItem">Services</Link>
                  <Link to="/#reviews" className="menuItem">Reviews</Link>
                  {props.mode === "activeWhite"
                     ? <Link to="/#portfolio" className="menuItem--activeBorder">Portfolio</Link>
                     : <Link to="/#portfolio" className={checkLocation() ? "menuItem--active" : "menuItem"}>Portfolio</Link>
                  }
                  <Link to="/#technologies" className="menuItem">Technologies</Link>
                  <Link to="/#contact" className="menuItem">Contact Us</Link>
                  </div>
                  <div className="hidden md:block">
                  <a href='https://calendly.com/andreytyndyk/30min' target='call'>
                        <div className={props.mode === "activeWhite" ? "menu__bookCallBtn" :"menu__bookCallBtn--white"} >
                           <span className="text-xs lg:text-base"><span className=''>Book a </span>Call</span>
                           <img src={callDark} className="w-4 lg:w-auto ml-3"/>
                        </div>
                     </a>
                  </div>
            </div>
            </div>
            {/* </div> */}

            <div className={isOpen ? "burger--active md:hidden block z-40" : "md:hidden block z-40 burger"}>
               <div className="burgerIcon">
                  <Hamburger toggled={isOpen} toggle={setOpen} size={25} />
               </div>
            </div>
         </>
      )
   }

   const loadMenu = () => {
      switch (props.mode) {
         case 'white':
            return <DefaultMenu />
         default:
            return <DefaultMenu />
      }
   }


   return (
      <>
        
         {loadMenu()}

         {isOpen ?
            <div className="phoneMenu__wrapper" onClick={(e) => closePhoneMenu(e)}>
               <div className="phoneMenu" data-aos="fade-left" data-aos-duration="500">
                  <div className="flex flex-col py-20 px-8">
                     {/* <Link to="/#home" className="phoneMenu__item" activeClassName="menuItem--active">Home</Link> */}
                     <Link to="/#services" className="phoneMenu__item" activeClassName="menuItem--active">Services</Link>
                     <Link to="/#reviews" className="phoneMenu__item" activeClassName="menuItem--active">Reviews</Link>
                     <Link to="/#technologies" className="phoneMenu__item" activeClassName="menuItem--active">Technologies</Link>
                     <Link to="/#portfolio" className="phoneMenu__item phoneMenu__itemPortfolio" activeClassName="menuItem--active">Portfolio</Link>
                     <Link to="/#contact" className="phoneMenu__item" activeClassName="menuItem--active">Contact Us</Link>
                     <a href='https://calendly.com/andreytyndyk/30min' className="mt-5 lowercase flex items-center justify-center w-full border border-red py-3 border border-lightBlue rounded-full"  target='call'>
                        <span className="uppercase ml-3 font-medium pt-1 text-lightBlue mr-3">Book a Call</span>
                        <img src={callBlue} />
                     </a>
                     {/* <Link to="/#contact" className="phoneMenu__requestBtn text-center text-sm">leave request</Link> */}
                  </div>
               </div>
            </div>
            : null}
      </>
   )
}

export default Menu
