import React from 'react';
import { useLocation } from 'react-router-dom'
import Menu from '../../components/menu/menu';
import OtherProjects from '../../components/otherProjects/otherProjects'
import './formOp.scss';
import '../gasTrainer/gasTrainer.scss';

const laptop1 = '/imgSvg/formOp/laptop1.svg';
const screen1 = '/imgSvg/formOp/screen1.svg';
const screen2 = '/imgSvg/formOp/screen2.svg';
const screen3 = '/imgSvg/formOp/screen3.svg';
const screen4 = '/imgSvg/formOp/screen4.svg';
const screen5 = '/imgSvg/formOp/screen5.svg';
const screen6 = '/imgSvg/formOp/screen6.svg';
const screen7 = '/imgSvg/formOp/screen7.svg';
const screen7Mobile = '/imgSvg/formOp/screen7-mobile.svg';
const screen8 = '/imgSvg/formOp/screen8.svg';
const screen9 = '/imgSvg/formOp/screen9.svg';
const screen10 = '/imgSvg/formOp/screen10.svg';
const screen11 = '/imgSvg/formOp/screen11.svg';
const screen11Mobile = '/imgSvg/formOp/screen11-mobile.svg';
const selectraLogo = '/imgSvg/selectra/selectraLogo.svg';


const FormOp = () => {
    const location = useLocation();

    return (

        <div>
            <Menu />
            <div className="relative formOp__section">
                <div className="flex flex-col md:flex-row justify-center md:justify-between py-12 md:pt-44 md:pb-10 gap-10">
                    <div className='flex-col flex-1 mt-10 lg:mt-28'>
                        <div className="text-3xl md:text-5xl lg:text-7xl font-medium mx-auto w-max md:mx-0">FormOp</div>
                        <div className='flex gap-3 mt-1 w-max mx-auto md:mx-0'>
                            <span className='text-lg md:text-2xl'>by</span>
                            <span><img src={selectraLogo} className="w-32" /></span>
                        </div>

                        <div className='mt-8 gasTrainer__bannerText'>
                        FormOp helps sales professionals find the best gas, electricity, and telecom offers for clients based on location, apartment size, and customer type. Secure individual access ensures a streamlined and efficient sales process.
                        </div>
                    </div>
                    <div className="flex-2"><img src={laptop1} className="w-full" /></div>
                </div>

                <div className='flex lex-col xs:flex-row gap-5 mt-5 sm:mt-20 w-full lg:w-10/12 mx-auto'>
                    <div className='formOp__gradientBlock'>
                        <div className=""><img src={screen1} className="w-auto sm:w-32" /></div>
                    </div>
                    <div className='formOp__gradientBlock'>
                        <div className=""><img src={screen2} className="w-auto sm:w-32" /></div>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row gap-5 mt-5 mb-10 sm:mb-32 w-full lg:w-10/12 mx-auto'>
                    <div className='formOp__gradientBlock '>
                        <div className=""><img src={screen3} className="w-44" /></div>
                    </div>
                    <div className='formOp__gradientBlock'>
                        <div className=""><img src={screen4} className="w-auto md:w-72" /></div>
                    </div>
                </div>

                <div className='my-10 w-10/12 sm:w-6/12 mx-auto'>
                    <p>FormOp is a smart sales platform that helps professionals find and compare the best utility and service offers for clients. By analyzing key factors like location, apartment size, and customer type, it provides tailored recommendations for gas, electricity, and telecom plans. Each salesperson has an individual account with restricted access, ensuring data security and a focused workflow.</p>
                    <p className='mt-2'> Admins can manage and update offers, keeping information accurate and up-to-date. With FormOp, sales teams can quickly find the best deals, improve efficiency, and close more sales.</p>
                </div>

                <div className='formOp__gradientSection flex flex-col md:flex-row gap-5 p-6 sm:p-20 my-10 sm:my-32'>
                    <img src={screen5} className="" />
                    <img src={screen6} className="" />
                </div>

                <div className='my-10 sm:my-32'>
                    <img src={screen7} className="hidden sm:block" />
                    <img src={screen7Mobile} className="block sm:hidden" />
                </div>

                <div className='formOp__gradientSection gap-5 p-6 sm:p-20 my-10 sm:my-32 box-border'>
                    <div className="flex  flex flex-col md:flex-row gap-5 overflow-hidden box-border">
                        <div className='flex-1'>
                            <img src={screen8} />
                        </div>
                        <div className='flex-1'>
                            <img src={screen9} />
                        </div>
                        <div className='flex-1'>
                            <img src={screen10} />
                        </div>
                    </div>
                </div>

                <div className='my-10 sm:my-20'>
                    <img src={screen11} className="hidden sm:block" />
                    <img src={screen11Mobile} className="block sm:hidden" />
                </div>

            </div>
            <OtherProjects location={location.pathname} />

        </div>
    )
}

export default FormOp