// project photos
const vocabulary = './imgSvg/vocabulary-project.svg';
const tickets = './imgSvg/ticket-project.svg';
const dogWalking = './imgSvg/dogWalking-project.svg';
const stocks = './imgSvg/stocks-project.svg';
const movies = './imgSvg/movies-project.svg';
const gasTrainer = './imgSvg/gasTrainer-project.svg';
const formOp = './imgSvg/formOp2-project.svg';
const siteForPrint = './imgSvg/siteForPrint-project.svg';
const selectra = './imgSvg/selectra-project.svg';
const parking = './imgSvg/parking-project.svg';


export const projects = [
   { name: "RW", desc: "Training in the English language", img: vocabulary, link: "/rw", category: ["ui", "ux", "mobile", "web", "watch", "extension", "education"] },
   { name: "Tickets", desc: "Save your money by comparing prices", img: tickets, link: "/tickets", category: ["ui", "ux", "web", "iot"] },
   { name: "Dog Walking", desc: "Application for dog walking service", img: dogWalking, link: "/dogWalking", category: ["ui", "ux", "web", "mobile"] },
   { name: "Monit", desc: "Mob app for notification about stocks", color: "light", img: stocks, link: "/monit", category: ["mobile"] },
   { name: "Twist", desc: "Movies, series and TV shows online", color: "light", img: movies, link: "/twist", category: ["ui", "ux", "media", "mobile", "web"] },
   { name: "Parking", desc: "The parking app", color: "dark", img: parking, link: "/parking", category: ["ui", "ux", "media", "mobile", "web"] },
   { name: "Site for print", desc: "Quality printing on t-shirts and textile products", color: "dark", img: siteForPrint, link: "/siteForPrint", category: ["ui", "ux", "media", "mobile", "web"] },
   { name: "Selectra", desc: 'CRM system', color: "light", img: selectra, link: "/selectra", category: ["ui", "ux", "media", "mobile", "web"] },
   { name: "Gas Trainer", desc: "The app that simplifies tracking natural gas usage and expenses", color: "light", img: gasTrainer, link: "/gas-trainer", category: ["ui", "ux", "media", "mobile", "web"] },
   { name: "FormOp", desc: "A CRM system that calculates gas costs", color: "dark", img: formOp, link: "/formOp", category: ["ui", "ux", "media", "mobile", "web"] },
]

export const technologies = [
  { name: "JS", img: './img/technology/js.png' },
  { name: "React", img: './img/technology/react.png' },
  { name: "Angular", img: './img/technology/angular.png' },
  { name: "Redux", img: './img/technology/redux.png' },
  { name: "Node.js", img: './img/technology/node.png' },
  { name: "Express", img: './img/technology/express.png' },
  { name: "GraphQL", img: './img/technology/graphQL.png' },
  { name: "MongoDB", img: './img/technology/mongoDB.png' },
  { name: "MySQL", img: './img/technology/mySQL.png' },
  { name: "Redis", img: './img/technology/redis.png' },
  { name: "Docker", img: './img/technology/docker.png' },
  { name: "Ansible", img: './img/technology/ansible.png' },
  { name: "AWS", img: './img/technology/aws.png' },
  { name: "Google Cloud", img: './img/technology/googleCloud.png' },
  { name: "Firebase", img: './img/technology/firebase.png' },
  { name: "Ionic", img: './img/technology/ionic.png' },
]

export const feedbacks = [
   { company: "CEO at Belleville", client: "Client of Company", feedback: "Thanks to our frequent discussions, we were able to choose the best way forward together." },
   { company: "CEO & Founder at RTCLogic", client: "Client of Company", feedback: "Tinsa Soft impressed us with their 'can do' attitude..." },
   { company: "Business Development Manager at Selectra", client: "Client of Company", feedback: "One of the best aspects of working with Tinsa Soft is the facility and rapidity in communicating with each other." },
  //  { company: "CTO Property Company", client: "Client of Company", feedback: "Thanks to our frequent discussions, we were able to choose the best way forward together." },
]

export class ContentData {

  static get Projects() {
    return projects;
  }

  static get Technologies() {
    return technologies;
  }

  static get Feedbacks() {
   return feedbacks;
 }
}