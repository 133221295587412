import React from 'react'
import { useLocation } from 'react-router-dom'
import Menu from '../../components/menu/menu';
import OtherProjects from '../../components/otherProjects/otherProjects'
import './parking.scss';

// img 
const bannerMobile = '/imgSvg/parking/bannerMobile.svg';
// const slogan = '/imgSvg/parking/slogan.svg';
const slogan1 = '/imgSvg/parking/slogan1.svg';
const slogan2 = '/imgSvg/parking/slogan2.svg';
const slogan3 = '/imgSvg/parking/slogan3.svg';
const icon1 = '/imgSvg/parking/icon1.svg';
const icon2 = '/imgSvg/parking/icon2.svg';
const icon3 = '/imgSvg/parking/icon3.svg';
const icon4 = '/imgSvg/parking/icon4.svg';
const mobile2 = '/imgSvg/parking/mobile2.svg';
const mobile3 = '/imgSvg/parking/mobile3.svg';
const mobile4 = '/imgSvg/parking/mobile4.svg';
const mobile5 = '/imgSvg/parking/mobile5.svg';
const mobile6 = '/imgSvg/parking/mobile6.svg';
const mobile7 = '/imgSvg/parking/mobile7.svg';
const mobile8 = '/imgSvg/parking/mobile8.svg';
const mobile9 = '/imgSvg/parking/mobile9.svg';
const mobile10 = '/imgSvg/parking/mobile10.svg';
const typography = '/imgSvg/parking/typography.svg';
const dots1 = '/imgSvg/parking/dots1.svg';
const dots2 = '/imgSvg/parking/dots2.svg';
const dots3 = '/imgSvg/parking/dots3.svg';


const Parking = (props) => {

    const location = useLocation();

    return (
        <div className='selectra'>
            <Menu />

            <div className='relative'>
                <div className='absolute top-0 left-0 w-full lg:w-7/12'><img src="./img/parking/gradientCircle1.png" alt="banner gradient illustration" /></div>
                <div className="parking__section flex flex-col lg:flex-row  pt-32 sm:pt-44 md:pb-10 gap-10 items-center justify-evenly">
                    <div className='z-10 relative flex flex-col gap-4 sm:gap-7 items-start w-6/12 lg:w-5/12 xl:w-fit'>
                        <img src={slogan1} alt="slogan" data-aos="fade-up"
                            data-aos-duration="1000"
                        />
                        <img src={slogan2} alt="slogan" data-aos="fade-up"
                            data-aos-duration="1300" />
                        <img src={slogan3} alt="slogan" data-aos="fade-up"
                            data-aos-duration="1600" />
                    </div>
                    <div className='w-10/12 sm:w-max' data-aos="zoom-in" data-aos-duration="1500" data-aos-easing="ease">
                        <img src={bannerMobile} alt="mobile" />
                    </div>
                </div>
                <div className='absolute top-72 lg:top-20 right-0 w-6/12 lg:w-3/12'><img src="./img/parking/gradientCircle2.png" alt="banner gradient illustration" /></div>
            </div>

            <div className='parking__section py-12 sm:py-32'>
                <div className='flex flex-col md:flex-row gap-5 sm:gap-10 md:gap-20'>
                    <div className='flex flex-row gap-5 sm:gap-10 md:gap-20'>
                        <div className='flex flex-col items-center text-center w-full relative'>
                            <div className='hidden md:block absolute top-16 -right-24'><img src={dots1} alt='dots icon' /></div>
                            <div className='h-36 flex items-center px-5 z-20'>
                                <img src={icon2} alt="icon section" />
                            </div>
                            <p className='text-lg font-semibold mt-5'>Location</p>
                            <p className='parking__textLight mt-1'>Easily find your way through the app with our intuitive interface. From scanning your ticket’s QR or barcode to viewing your payment history. </p>
                        </div>
                        <div className='flex flex-col items-center text-center w-full relative'>
                            <div className='hidden md:block absolute top-16 -right-24'><img src={dots2} alt='dots icon' /></div>
                            <div className='h-36 flex items-center bg-white px-5 z-20'>
                                <img className='py-10' src={icon1} alt="icon section" />
                            </div>
                            <p className='text-lg font-semibold mt-5'>Verification</p>
                            <p className='parking__textLight mt-1'>Rest assured your information is protected. Once you scan your parking ticket, our system securely verifies the details so you know you’re paying for the correct ticket every time. </p>
                        </div>
                    </div>
                    <div className='flex gap-5 sm:gap-10 md:gap-20'>
                        <div className='flex flex-col items-center text-center w-full relative'>
                            <div className='hidden md:block absolute top-16 -right-24'><img src={dots3} alt='dots icon' /></div>
                            <div className='h-36 flex items-center bg-white px-5 z-20'>
                                <img src={icon3} alt="icon section" />
                            </div>
                            <p className='text-lg font-semibold mt-5'>Payment</p>
                            <p className='parking__textLight mt-1'>No more long queues or confusing online forms. Complete your payment directly from the app using your preferred credit or debit card. </p>
                        </div>
                        <div className='flex flex-col items-center text-center w-full '>
                            <div className='h-36 flex items-center px-5 z-20'>
                                <img src={icon4} alt="icon section" />
                            </div>
                            <p className='text-lg font-semibold mt-5'>Notification</p>
                            <p className='parking__textLight mt-1'>Stay in the loop with instant notifications. Get alerts as soon as your payment is processed, and receive friendly reminders before deadlines. </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='py-12 sm:py-20 relative'>
                <div className='absolute -top-56 left-0 w-4/12 block'><img className='transform rotate-180' src="./img/parking/gradientCircle2.png" alt="gradient illustration" /></div>
                <div className='parking__section z-40 relative'>
                    <div className='flex flex-col md:flex-row justify-between gap-1 items-center'>
                        <p className='text-3xl sm:text-5xl md:text-7xl xl:text-8xl font-extrabold uppercase' data-aos="fade" data-aos-duration="1000">Payment</p>
                        <div className='parking__subtitle text-center md:text-right'>
                        Scan your parking ticket, verify its details, pay instantly, and receive updates through an intuitive, secure app—no queues, no hassles.
                        </div>
                    </div>
                    <div className='flex py-10 gap-3 sm:gap-10 justify-center sm:justify-between flex-wrap sm:flex-nowrap'>
                        <div className='w-5/12 sm:w-fit'><img src={mobile2} alt="mobile screenshot" /></div>
                        <div className='w-5/12 sm:w-fit'><img src={mobile3} alt="mobile screenshot" /></div>
                        <div className='w-5/12 sm:w-fit'><img src={mobile4} alt="mobile screenshot" /></div>
                        <div className='w-5/12 sm:w-fit'><img src={mobile5} alt="mobile screenshot" /></div>
                    </div>
                </div>
                <div className='absolute top-20 right-0 w-4/12'><img src="./img/parking/gradientCircle3.png" alt="gradient illustration" /></div>
            </div>

            <div className='parking__section py-12 sm:py-20'>
                <div className='flex flex-col md:flex-row justify-between gap-1 items-center'>
                    <p className='text-3xl sm:text-5xl md:text-7xl xl:text-8xl font-extrabold uppercase' data-aos="fade" data-aos-duration="1000">profile</p>
                    <div className='parking__subtitle text-center md:text-right'>
                    Create and manage your personal information, payment preferences, and account settings, keeping all your details organized in one secure place.
                    </div>
                </div>
                <div className='flex py-10 gap-3 justify-center sm:justify-between flex-wrap sm:flex-nowrap'>
                    <div className='w-5/12 sm:w-fit'><img src={mobile6} alt="mobile screenshot" /></div>
                    <div className='w-5/12 sm:w-fit'><img src={mobile7} alt="mobile screenshot" /></div>
                    <div className='w-5/12 sm:w-fit'><img src={mobile8} alt="mobile screenshot" /></div>
                    <div className='w-5/12 sm:w-fit'><img src={mobile9} alt="mobile screenshot" /></div>
                    <div className='w-5/12 sm:w-fit'><img src={mobile10} alt="mobile screenshot" /></div>
                </div>
            </div>

            <div className='parking__section py-12 sm:py-20'>
                <p className='text-3xl sm:text-5xl md:text-7xl xl:text-8xl font-extrabold uppercase' data-aos="fade" data-aos-duration="1000">typography</p>
                <div className='mt-5 sm:mt-16 flex flex-col lg:flex-row items-center gap-10 sm:gap-16 lg:gap-32'>
                    <div className='w-full md:w-9/12'>
                        <div><img className='w-5/12 lg:w-fit' src={typography} alt="typography" /></div>
                        <div className='mt-6 lg:mt-16'>
                            <p className='text-2xl font-bold uppercase'>typeface</p>
                            <div className='flex items-center gap-4 mt-2'>
                                <p className='text-xl font-medium'>Medium</p>
                                <div className='h-1 w-1 bg-parkingDark rounded-full'></div>
                                <p className='text-xl font-semibold'>Semi Bold</p>
                                <div className='h-1 w-1 bg-parkingDark rounded-full'></div>
                                <p className='text-xl font-bold'>Bold</p>
                            </div>
                        </div>
                    </div>
                    <div className='font-medium flex flex-col gap-5'>
                        <div className='flex'>
                            <div className='w-16 md:w-24 flex-none'><p className='text-2xl sm:text-3xl font-semibold'>H1</p></div>
                            <div><p className='text-lg sm:text-xl'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p></div>
                        </div>
                        <div className='flex'>
                            <div className='w-16 md:w-24 flex-none'><p className='text-xl sm:text-2xl font-semibold'>H2</p></div>
                            <div><p className='text-base sm:text-lg'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p></div>
                        </div>
                        <div className='flex'>
                            <div className='w-16 md:w-24 flex-none'><p className='ttext-lg sm:text-xl font-semibold'>H3</p></div>
                            <div><p className='text-sm sm:text-base'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p></div>
                        </div>
                    </div>
                </div>
            </div>


            <OtherProjects location={location.pathname} />

        </div>
    )
}

export default Parking
