import React from 'react';
import { useLocation } from 'react-router-dom'
import Menu from '../../components/menu/menu';

import OtherProjects from '../../components/otherProjects/otherProjects'
import './siteForPrint.scss';

// img 
const desktop = '/imgSvg/siteForPrint/desktop.svg';
const logo = '/imgSvg/siteForPrint/logo.svg';
const logoMobile = '/imgSvg/siteForPrint/logoMobile.svg';
const star = '/imgSvg/siteForPrint/star.svg';
const tagsSeparator = '/imgSvg/siteForPrint/tagsSeperator.svg';
const wordsSeparator = '/imgSvg/siteForPrint/wordsSeparator.svg';
const littleStar = '/imgSvg/siteForPrint/littleStar.svg';
const laptop = '/imgSvg/siteForPrint/laptop.svg';
const laptop2 = '/imgSvg/siteForPrint/laptop2.svg';
const laptop3 = '/imgSvg/siteForPrint/laptop3.svg';
const laptop4 = '/imgSvg/siteForPrint/laptop4.svg';
const laptop5 = '/imgSvg/siteForPrint/laptop5.svg';
const laptop3Full = '/imgSvg/siteForPrint/laptop3Full.svg';
const flower = '/imgSvg/siteForPrint/flower.svg';
const mobile1 = '/imgSvg/siteForPrint/mobile1.svg';
const mobile2 = '/imgSvg/siteForPrint/mobile2.svg';
const mobile3 = '/imgSvg/siteForPrint/mobile3.svg';
const mobile4 = '/imgSvg/siteForPrint/mobile4.svg';
const website = '/imgSvg/siteForPrint/website.svg';
const curvedLine = '/imgSvg/siteForPrint/curvedLine.svg';

const SiteForPrint = () => {

    const location = useLocation();

    return (
        <div>
            <Menu />
            <div className='hidden lg:block absolute top-10 left-0'>
                <img src={curvedLine} alt="curved line illustration" />
            </div>
            <div className="relative siteForPrint__section">

                <div className="siteForPrint__bannerWrapper flex items-center flex-col sm:flex-row py-12 pt-20 md:pt-44 md:pb-10 mx-auto w-full xl:w-max">
                    <div>
                        <img src={logo} className="hidden sm:block" />
                        <img src={logoMobile} className="w-8/12 mx-auto block sm:hidden" />
                    </div>
                    <div className="">
                        <img className="" src={desktop} />
                    </div>
                </div>

                <div className='siteForPrint__about'>
                    <div className='flex flex-col md:flex-row gap-10 justify-between'>
                        <div className='text-3xl lg:text-4xl xl:text-5xl'>
                            <div className='flex flex-nowrap items-center'>
                                <span>Ab</span>
                                <span><img src={star} alt="star" /></span>
                                <span>ut</span>
                                <span className='ml-2'>the</span>
                            </div>
                            <div> project</div>
                        </div>
                        <div className='siteForPrint__plainText'>
                            Thanks to our project, you can very easily order a print on the selected clothes. Just choose the picture to be printed and place it as you like. We will print everything quickly and qualitatively.
                        </div>
                    </div>
                    <div className='siteForPrint__aboutTagsSection mt-10 md:mt-16 flex justify-between'>
                        <div className='flex-1'>
                            <div className='siteForPrint__aboutTtitle'>DISCOVERY</div>

                            <div className='siteForPrint__aboutTagSectionWrapper'>


                                <div className='siteForPrint__aboutTagSection'>
                                    <img src={tagsSeparator} alt="tagsSeparator" />

                                    <div className='siteForPrint__aboutTag siteForPrint__aboutTag--wide'>Research</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex-2 mt-5 md:mt-0'>
                            <div className='siteForPrint__aboutTtitle'>Design</div>
                            <div className='siteForPrint__aboutTagSectionWrapper'>

                                <div className='siteForPrint__aboutTagSection'>
                                    <img src={tagsSeparator} alt="tagsSeparator" />

                                    <div className='flex w-full'>
                                        <div className='siteForPrint__aboutTag'>Moodboard</div>
                                        <div className='siteForPrint__aboutTag'>Ui Design</div>
                                    </div>
                                    <div className='flex mt-3 w-full md:w-max ml-auto'>
                                        <div className='siteForPrint__aboutTag'>Design System</div>
                                        <div className='siteForPrint__aboutTag'>Mob Adaptive</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex-1 mt-5 md:mt-0'>
                            <div className='siteForPrint__aboutTtitle'>DEVELOPMENT</div>
                            <div className='siteForPrint__aboutTagSectionWrapper'>

                                <div className='siteForPrint__aboutTagSection'>
                                    <img src={tagsSeparator} alt="tagsSeparator" />

                                    <div className='siteForPrint__aboutTag siteForPrint__aboutTag--wide'>Webflow</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='py-12 md:pt-26 md:pb-10">'>
                    <div className='flex text-2xl md:text-4xl gap-3 items-center'>
                        <p>Color</p>
                        <img className='opacity-70 h-6 mt-1' src={wordsSeparator} alt='wordsSeparator' />
                        <p>Typography</p>
                    </div>
                    <div className='mt-10 md:mt-28 w-full xl:w-max mx-auto'>
                        <div className='flex flex-wrap gap-x-3 gap-y-10 w-full'>
                            <div className='siteForPrint__color'>
                                <div className='siteForPrint__colorBase'>
                                    <div className='flex items-center gap-4'>
                                        <img className='w-2' src={littleStar} alt="littleStar" />
                                        <span>4EA9FD</span>
                                        <img className='w-2' src={littleStar} alt="littleStar" />
                                    </div>
                                    <div className='siteForPrint__colorSample siteForPrint__colorSample--blue'></div>
                                </div>
                            </div>
                            <div className='siteForPrint__color'>
                                <div className='siteForPrint__colorBase'>
                                    <div className='flex items-center gap-4'>
                                        <img className='w-2' src={littleStar} alt="littleStar" />
                                        <span>333333</span>
                                        <img className='w-2' src={littleStar} alt="littleStar" />
                                    </div>
                                    <div className='siteForPrint__colorSample siteForPrint__colorSample--dark'></div>
                                </div>
                            </div>
                            <div className='siteForPrint__color'>
                                <div className='siteForPrint__colorBase'>
                                    <div className='flex items-center gap-4'>
                                        <img className='w-2' src={littleStar} alt="littleStar" />
                                        <span>F1F8FF</span>
                                        <img className='w-2' src={littleStar} alt="littleStar" />
                                    </div>
                                    <div className='siteForPrint__colorSample siteForPrint__colorSample--lightBlue'></div>
                                </div>
                            </div>
                            <div className='siteForPrint__color'>
                                <div className='siteForPrint__colorBase'>
                                    <div className='flex items-center gap-4'>
                                        <img className='w-2' src={littleStar} alt="littleStar" />
                                        <span>D2E9FF</span>
                                        <img className='w-2' src={littleStar} alt="littleStar" />
                                    </div>
                                    <div className='siteForPrint__colorSample siteForPrint__colorSample--lightBlue2'></div>
                                </div>
                            </div>
                            <div className='siteForPrint__color'>
                                <div className='siteForPrint__colorBase'>
                                    <div className='flex items-center gap-4'>
                                        <img className='w-2' src={littleStar} alt="littleStar" />
                                        <span>929090</span>
                                        <img className='w-2' src={littleStar} alt="littleStar" />
                                    </div>
                                    <div className='siteForPrint__colorSample siteForPrint__colorSample--lightGray'></div>
                                </div>
                            </div>
                        </div>

                        <div className='flex gap-5 sm:gap-20 md:gap-36 items-start sm:items-center mt-20'>
                            <div className='siteForPrint__darkText'>
                                <p className='whitespace-nowrap	'>Jost Regular</p>
                                <p className='text-3xl sm:text-5xl xl:text-9xl mt-5'>Aa123</p>
                            </div>
                            <div className='text-xl md:text-2xl siteForPrint__blueText'>
                                <p className='break-all	'>abcdefghijklmnopqrstuwzyz</p>
                                <p className='uppercase break-all	'>abcdefghijklmnopqrstuwzyz</p>
                                <p className='break-all	'>0123456789</p>
                            </div>
                        </div>

                        <div className='flex gap-5 sm:gap-20 md:gap-36 items-start sm:items-center mt-20'>
                            <div className='siteForPrint__darkText'>
                                <p className='whitespace-nowrap	'>Jost Regular</p>
                                <p className='text-3xl sm:text-5xl xl:text-9xl mt-5 font-medium'>Aa123</p>
                            </div>
                            <div className='text-xl md:text-2xl siteForPrint__blueText font-medium'>
                                <p className='break-all	'>abcdefghijklmnopqrstuwzyz</p>
                                <p className='uppercase break-all	'>abcdefghijklmnopqrstuwzyz</p>
                                <p className='break-all'>0123456789</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex text-2xl md:text-4xl gap-3 items-center mt-10 md:mt-20'>
                    <p>Ui Desktop</p>
                </div>
            </div>
            <div className='siteForPrint__sectionNotCentered'>
                <div className='flex gap-5'>
                    <div className='mx-auto'>
                        <img className='' src={laptop} alt="laptop" />
                    </div>
                    <div className='hidden xl:block mt-32'>
                        <img src={laptop2} className='mt-2' alt="laptop" />
                    </div>
                </div>
                <div>
                    <img className='mt-10 sm:mt-20' src={laptop3Full} alt="laptop" />
                </div>
            </div>

            <div className='siteForPrint__section'>
                <div className='flex flex-col sm:flex-row justify-between items-end gap-5 my-10 sm:my-20 md:my-44'>
                    <div className='relative'>
                        <img className='relative z-10' src={laptop4} alt="laptop" />
                        <div className='hidden sm:block absolute -z-10 -right-12 top-20' style={{ transform: 'rotate(180deg)' }}>
                            <img src={flower} alt="flower" />
                        </div>
                    </div>
                    <div>
                        <img className='' src={laptop5} alt="laptop" />
                    </div>
                </div>

                <div className='flex text-2xl md:text-4xl gap-3 items-center mt-20'>
                    <p>Ui Adaptive</p>
                </div>

                <div className='flex my-10 gap-5'>
                    <div className='w-4/12'>
                        <img className='w-full' src={mobile1} alt="mobile1" />
                    </div>
                    <div className='w-full flex gap-5 flex-col'>
                        <div className='flex gap-5'>
                            <div className='flex-1'> <img className='w-full' src={mobile2} alt="mobile1" /></div>
                            <div className='flex-1'> <img className='w-full' src={mobile3} alt="mobile1" /></div>
                            <div className='flex-1'> <img className='w-full' src={mobile4} alt="mobile1" /></div>
                        </div>
                        <div>
                            <div> <img className='w-full' src={website} alt="mobile1" /></div>

                        </div>
                    </div>
                </div>
            </div>

            <OtherProjects location={location.pathname} />

        </div>

    )
}

export default SiteForPrint