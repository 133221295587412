import React from 'react'
import { useLocation } from 'react-router-dom'
import Menu from '../../components/menu/menu';
import OtherProjects from '../../components/otherProjects/otherProjects'
import './selectra.scss';

// img 
const banner = '/imgSvg/selectra/banner.svg';
const bannerMobile = '/imgSvg/selectra/banner-mobile.svg';
const selectra = '/imgSvg/selectra/selectra.svg';
const bannerScreens = '/imgSvg/selectra/bannerScreens.svg';
const bannerScreensMobile = '/imgSvg/selectra/bannerScreens-mobile.svg';
const designElenents = '/imgSvg/selectra/designElenents.png';
const designElenentsMobile = '/imgSvg/selectra/designElenents-mobile.png';
const helvetica = '/imgSvg/selectra/helvetica.svg';
const laptop1 = '/imgSvg/selectra/laptop1.svg';
const laptop2 = '/imgSvg/selectra/laptop2.svg';
const medals = '/imgSvg/selectra/medals.svg';
const screen1 = '/imgSvg/selectra/screen1.svg';
const screen2 = '/imgSvg/selectra/screen2.svg';
const screen3 = '/imgSvg/selectra/screen3.svg';
const screen4 = '/imgSvg/selectra/screen4.svg';
const screen5 = '/imgSvg/selectra/screen5.svg';
const screen6 = '/imgSvg/selectra/screen6.svg';
const screen7 = '/imgSvg/selectra/screen7.svg';



const Selectra = (props) => {

    const location = useLocation();

    return (
        <div className='selectra'>
            <Menu />
            <div className='relative'>
                <div className='selectra__bannerImgWrapper'>
                    <img src={banner} className="hidden sm:block w-full" alt='banner background' />
                    <img src={bannerMobile} className="block sm:hidden w-full" alt='banner background' />
                </div>
                <div className='absolute top-36 xl:top-44 2xl:top-56 flex items-center flex-col w-full md:w-10/12 left-1/2 transform -translate-x-1/2'>
                    <img src={selectra} alt="selectra logo" />
                    <img src={bannerScreens} className='hidden sm:block mt-10 mr-2 xl:mr-16' alt="banner image" />
                    <img src={bannerScreensMobile} className='block sm:hidden' alt="banner image" />
                </div>
            </div>

            <div className="selectra__section">
                <div className="relative mt-10 sm:mt-0 py-20 sm:py-5  md:py-10 xl:py-24">
                    <p className='text-center py-5 text-3xl xl:text-5xl font-bold'>Colors</p>
                    <div className='flex gap-5 md:gap-10 mx-auto w-max mt-2 sm:mt-5 xl:mt-10'>
                        <div className='rounded-full h-10 md:h-16 xl:h-20 w-10 md:w-16 xl:w-20 selectra__colorBlue1'></div>
                        <div className='rounded-full h-10 md:h-16 xl:h-20 w-10 md:w-16 xl:w-20 selectra__colorBlue2'></div>
                        <div className='rounded-full h-10 md:h-16 xl:h-20 w-10 md:w-16 xl:w-20 selectra__colorYellow'></div>
                        <div className='rounded-full h-10 md:h-16 xl:h-20 w-10 md:w-16 xl:w-20 selectra__colorLightBlue'></div>
                        <div className='rounded-full h-10 md:h-16 xl:h-20 w-10 md:w-16 xl:w-20 selectra__colorGray'></div>
                    </div>
                </div>

                <div className="relative py-4 sm:py-12 xl:py-24">
                    <p className='text-center py-5 text-3xl xl:text-5xl font-bold'>Font</p>
                    <div className='flex gap-5 xl:gap-10 mt-2 sm:mt-5 xl:mt-10 items-center flex-col'>
                        <div className='w-7/12 flex justify-center'><img src={helvetica} alt="font helvetica" /></div>
                        <div className='selectra__blueText text-2xl xl:text-3xl flex gap-3 items-center'>
                            <p className='font-light'>Light</p>
                            <div className='h-1.5 w-1.5 bg-selectraBlue rounded-full'></div>
                            <p className='font-noraml'>Regular</p>
                            <div className='h-1.5 w-1.5 bg-selectraBlue rounded-full'></div>
                            <p className='font-bold'>Bold</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='my-12 md:my-24 py-20 relative'>
                <div className='selectra__gradientBg absolute top-0 left-0'></div>
                <div className="selectra__section flex flex-col lg:flex-row gap-10 text-white">
                    <div className='flex-1'>
                        <p className='w-10/12 mx-auto text-center sm:text-left text-sm sm:text-base'>A private CRM system designed to help sales professionals manage clients, find the best products, send and track contracts, subscribe clients to newsletters, and monitor sales progress—all while offering different access levels and a built-in rewards system.</p>
                        <div className='mt-14'><img className='w-full lg:w-auto' src={screen1} alt="screen" /></div>
                    </div>
                    <div className='flex-1'>
                        <p className='w-10/12 mx-auto hidden lg:block'>A guided step-by-step process ensures salespersons collect all essential client information without missing key details, making the sales process more structured and efficient.</p>
                        <div className='mt-0 lg:mt-14'><img className='w-full lg:w-auto' src={screen2} alt="screen" /></div>
                    </div>
                </div>
            </div>

            <div className='my-12 md:my-24 selectra__screensSection'>
                <div className='flex flex-col md:flex-row gap-10 w-full md:w-max ml-none md:ml-auto items-center'>
                    <div className='selectra__plainText'>
                        <p>Easily create and manage contract templates with dynamic fields that automatically fill based on predefined scripts and business rules, streamlining document generation and reducing manual work.</p>
                    </div>
                    <div className='flex-none'><img src={screen3} alt="screen" /></div>
                    <div className='flex-none'><img src={screen4} alt="screen" /></div>
                </div>
                <div className='flex flex-col md:flex-row gap-10 w-full md:w-max ml-none md:ml-auto items-center mt-10'>
                    <div className='flex-none'><img src={screen5} alt="screen" /></div>
                    <div className='flex-none'><img src={screen6} alt="screen" /></div>
                    <div className='flex-none'><img src={screen7} alt="screen" /></div>
                </div>
            </div>

            <div className='py-10 md:py-18 selectra__section'>
                <div className='flex flex-col-reverse sm:flex-row gap-10 lg:gap-20 items-center'>
                    <div className='flex-1'>
                        <div className=''><img src={laptop1} alt="screen" /></div>
                    </div>
                    <div className='selectra__plainText'>
                        <p>Seamlessly connect with external services to track product sales, monitor client interactions, and gain real-time insights for better decision-making and improved customer management.</p>
                    </div>
                </div>
            </div>

            <div className='py-10 md:py-18 selectra__section'>
                <div className='flex flex-col sm:flex-row gap-10 lg:gap-20 items-center'>
                    <div className='selectra__plainText flex flex-col items-center sm:items-start'>
                        <p>Motivate your sales team with a built-in rewards system that tracks performance, assigns points for completed deals, and offers incentives to drive engagement and success.</p>
                        <div className='mt-5'><img src={medals} alt="medals" /></div>
                    </div>
                    <div className='flex-1'>
                        <div className=''><img src={laptop1} alt="screen" /></div>
                    </div>
                </div>
            </div>

            <div className="relative py-12 md:py-24">
                <p className='text-center py-5 text-3xl xl:text-5xl font-bold'>Design Elements</p>
                <div className='w-full mt-10'>
                    <img src={designElenents} className='hidden sm:block' alt="design elements" />
                    <img src={designElenentsMobile} className='block sm:hidden' alt="design elements" />
                </div>
            </div>

            <OtherProjects location={location.pathname} />

        </div>
    )
}

export default Selectra
